.accordion {
    .border-top {
        border-color: theme-color('primary-2') !important;
    }

    .accordion-panel-title {
        @extend h4;
        @extend .pt-2;
        color: theme-color('primary-2') !important;
        path {
            fill: theme-color('primary');
        }
    }

    .accordion-content {
        border-color: theme-color('primary-2') !important;

    }

}
