.header-section {
    @extend .header-inner;
    @extend .p-0;
    @extend .jarallax;
    @extend .position-relative;

    margin-top: 8em;

    @include media-breakpoint-down (sm) {
        margin-top: 7em;
    }

    @extend .bg-primary;

    // @extend .o-hidden;

    .header-content {
        @extend .container;
        @extend .py-0;

        .row {
            @extend .layer-2;
            @extend .my-4;
            @extend .my-md-6;
        }
    }


    h1 {
        color: white;
        @extend .display-4;
        @extend .mb-0;
    }


    &.story {
        // border: 2px solid  red;
        @extend .pt-6;
        @extend .mb-0;
        @extend .bg-white;

        h1 {
            color: theme-color('primary');
        }

        .header-content {
            min-height: initial;
        }
    }


    p {
        &.h1 {
            @extend h1;
            color: theme-color('primary-2') !important;
        }
    }

    .lead {
        @extend .mb-0;
    }

    // div[id*="jarallax-container"] {
    //     mix-blend-mode: multiply;
    // }

    // &:before {
    //     content: '';
    //     z-index: -1;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     mix-blend-mode: screen;
    //     // background: theme-color('primary') !important;
    //     background: linear-gradient(180deg, rgba(theme-color('primary'), 0.5) 0%, rgba(theme-color('primary'), 0.75) 100%);
    // }

    .btn {
        // font-size: 1.25em !important;
        @extend .mt-4;
        background: none;
        color: white;

        &:hover {
            background: white;
            color: theme-color('primary-2');
        }
    }
}


.header-content {
    min-height: 350px;

    @media (min-width: 1366px) and (max-width: 1680px) {
        min-height: 280px;
    }
    @media (min-width: 960px) and (max-width: 1366px) {
        min-height: 220px;
    }
    @include media-breakpoint-down(md) {
        min-height: 180px;
    }
}