form {
    fieldset {
        @extend .form-row;

        .clearfix {
            @extend .col-12;
        }

        input,
        textarea {
            @extend .form-control;
        }

        label {
            margin: 1em 0 0;
            font-size: 0.85em;
        }
    }

    .error {

        input,
        textarea {
            border-color: $red;
        }

        .help-inline {
            color: $red;
            font-size: 0.85em;
        }
    }

    .form-navigation {
        ul {
            list-style-type: none;
            padding: 1.5em 0.5em 0;
        }
    }
}

// .mauticform-innerform {
//     @extend .container;
// }


.mauticform-page-wrapper {
    // @extend .row;
    margin: 0 !important;
    width: 100%;

    color: theme-color('primary-2');

    .mauticform-label {
        @extend .h3;
        width: 100%;
    }

    h3 {
        @extend .mt-4;
    }

    .small {
        .mauticform-label {
            @extend .mt-2;
            font-weight: $font-weight-normal;
        }
    }

}

#mauticform_label_offertauftragsanfrage_datei_auswahlen {
    display: none;
}

form {}

.mauticform-row {
    display: flex !important;
    @extend .row;
    @extend .container;
    @include make-col(12);
}

.mauticform-text,
.mauticform-email,
.mauticform-tel,
.mauticform-select,
.mauticform-button-wrapper {
    @extend .form-group;

    input,
    textarea {
        @extend .form-control;
        border: none;
        background-color: #efefef;

        &::placeholder {
            color: rgba(theme-color('primary-2'), 0.5);
        }
    }

}

.mauticform-button-wrapper {
    @extend .justify-content-end;

    .btn-default {
        @extend .btn-primary-2;
        font-weight: $font-weight-normal;
    }

}

.mauticform-select {
    select {
        background-color: #efefef;
        // @extend .custom-select;
        @extend .form-control;
    }
}

.mauticform-file {}

.mauticform-errormsg {
    color: $red;
    font-size: 0.75em;
}

.mauticform-has-error {
    input {
        border-style: dashed !important;
        border-width: 2px;
        border-color: theme-color('primary');
    }
}

.mauticform-checkboxgrp {
    @extend .row;
    @extend .container;

    .mauticform-checkboxgrp-row {
        @extend .col-md-6;
        padding-left: 0;
        label {
            @extend .mb-0;
        }
    }
}


.mauticform-post-success {
    transition: all 0.5s;

    .mauticform-message {
        @extend h4;
        color: theme-color('primary');
        opacity: 1;
    }

    .mauticform-innerform {
        opacity: 0;
    }

    // border: 4px solid orange;
}
