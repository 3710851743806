// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

@import 'components/accordion';
@import 'components/buttons';
@import 'components/cards';
@import 'components/footer';
@import 'components/forms';
@import 'components/header-section';
@import 'components/latestNews';
@import 'components/layout';
@import 'components/loader';
@import 'components/maps';
@import 'components/modals';
@import 'components/navigation';
@import 'components/process';
@import 'components/slider';
@import 'components/stoerer';
@import 'components/tables';
@import 'components/tabs';
@import 'components/typography';
@import 'components/utility';

* {
    hyphens: auto;
}
strong {
    font-weight: $font-weight-bold;
}

.be-info {
    font-size: .75em;
    border: 2px solid $red;
    padding: .25em .5em;
    background-color: white;
    opacity: 0.7;
    position: absolute;
    z-index: 11;
}

.bg-verlauf {
    background: theme-color('primary');
    background: linear-gradient(225deg, theme-color('primary-2') 0%, theme-color('primary') 100%);
}

.story-kacheln {
    .card {
        border: none;
        @extend .hover-shadow-sm;
    }

    h3 {
        color: white;
    }

    .card-body {
        background: #efefef;
    }
}

.termine-kacheln,
.footer-form {

    .container {
        padding-bottom: 150px;
        @extend .layer-1;
    }

    @extend .banner-strap;
    @extend .pb-0;
    @extend .o-hidden;

    // @extend .bg-verlauf;

    // .card {
    //     border: none;
    //     // @extend .layer-1;
    //     // @extend .shadow-lg;
    // }

    .card-body {
        background-color: #fff;
    }
}

.footer-form {
    @extend .pt-0;
    @extend .mt-5;
}

.story-kacheln,
.termine-kacheln,
.blog-kacheln,
.more-items {

    h4 {
        font-family: $headings-font-family;
        font-size: 1.33333333em;
        color: theme-color('primary');
    }

    .card {
        border: none;
        min-height: 380px;
        overflow: hidden;
        border-radius: 0 !important;
        @extend .layer-1;
        @extend .shadow-lg;
    }

    .card-img-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-body {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        p {
            color: theme-color('primary-2');
        }

    }

    .itemInfo {
        @extend .small;
        @extend .mb-2;
        font-weight: $font-weight-bold;

        span {
            color: white;
        }
    }


    .blog-card {
        .card-body {
            color: white;

            h4 {
                color: white;
            }

            padding-top: 70%;

            .badge {
                border: none;
            }

            &:before {
                content: '';
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                position: absolute;
            }
        }
    }

}

.bg-alternate {
    .card-body {
        background: theme-color('primary-2');

        p {
            @extend .text-white;
        }
    }
}


.blog-card {
    height: 460px !important;

    @include media-breakpoint-down(lg) {
        height: 300px !important;
    }

    .card-body {
        @extend .d-flex;
        @extend .flex-column;
    }

}


.jonnitto-prettyembed-wrapper {
    border-radius: 0 !important;
    overflow: hidden;
}

.termine-kacheln,
.footer-form {

    h1,
    h2,
    h3 {
        @extend .text-light;
    }

    // @extend .bg-verlauf;
    // padding-bottom: 100px !important;
}

.divider {
    .bg-primary-4 {
        fill: lighten(theme-color('primary-2'), 35%) !important;
    }
}



.rounded {
    border-radius: $border-radius !important;
    overflow: hidden;
}
img.rounded, div.rounded{
    @include media-breakpoint-down (md) {
        border-radius: $border-radius !important;
    }
}

.table {
    font-family: $headings-font-family;
    width: 100%;

    table {
        width: 100%;
    }

    text-align: left;

    td {
        @extend .px-0;
    }

    td:first-child {
        // color: red;
        min-width: 200px;
        font-weight: $font-weight-bold;
    }
}

.jonnitto-image,
.jonnitto-prettyembed-wrapper {
    @extend p;
}

.table-links {
    a {
        font-weight: $font-weight-bold;
    }

    svg {
        transform: rotate(-180deg);
        display: none;
    }

    .hasLink {
        border-top: $border-width solid $primary-2;
        &.hover {
            cursor: pointer !important;
        }
        a {
            @extend h3;
            color: $primary-2;
            &:hover {
                color: $primary;
            }
        }
    }

    .hasLink {
        svg {
            display: inline-block;
        }
    }
}

.carousel-cell {}

$grid-gutter-widths: (
  sm: 20px,
  md: 30px,
  lg: 50px
);

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
    @include media-breakpoint-up($grid-breakpoint) {
      $grid-gutter-half-width: $grid-gutter-width / 2;
      .row:not(.no-gutters) {
        margin-right: -$grid-gutter-half-width;
        margin-left: -$grid-gutter-half-width;
      }
      .row:not(.no-gutters) > .col,
      .row:not(.no-gutters) > [class*="col-"] {
        padding-right: $grid-gutter-half-width;
        padding-left: $grid-gutter-half-width;
      }
      .container {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
      }
    }
  }

  .divider.flip-y.bg-primary-2 {
    svg {
        height: 75px;
        @include media-breakpoint-down (md) {
            height: 50px;
        }
        @include media-breakpoint-down (sm) {
            height: 20px;
        }
    }
  }