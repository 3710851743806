main {
    color: theme-color('primary-2');

    ul {
        list-style-type: none;
        padding-left: 1.5em;

        li:not(.nav-item) {
            &:before {
                content: '→' !important;
                position: absolute;
                margin-left: -1.5em;
                color: theme-color('primary');
            }
        }

    }

    p {
        a {
            text-decoration: underline;
        }
    }
}

a {
    font-weight: $font-weight-normal;
}

ul {
    &.list-unstyled {
        list-style-type: initial;
        padding-left: initial;

        li {
            &:before {
                content: initial;
                margin-left: initial;
                color: initial;
                position: initial;
            }
        }
    }
}

.hover-links {
    font-family: $headings-font-family;
    font-size: 1.3333333em;

    .link-block {
        position: relative;
        border-top: 2px solid theme-color('primary-3');
        @extend .mb-0;
        padding: .5em 1.25em .5em 0;

        a {
            display: block;
        }

        .text-right {
            position: absolute;
            right: 0;
            top: .75em;
            transform: rotate(-180deg);
        }
    }
}

.font-sans {
    font-family: 'Inter', sans-serif;
}

// .badge {
//     border-radius: 0;
//     font-family: $headings-font-family;
//     font-size: 13px;
//     letter-spacing: 3px;
//     color: theme-color('primary') !important;
//     background: white;

//     font-weight: $font-weight-medium;
//     padding-top: 8px;
//     padding-bottom: 8px;

//     border: 2px solid theme-color('primary-2');
// }


.link-external {
    display: block;
}

h1,h2,h3,h4,.h1,.h2,.h3,.h4,.display-1,.display-2,.display-3,.display-4{
    letter-spacing: 0;
    line-height: 1.3em;
    color: theme-color('primary-2');
}


p {
    &.h1 {
        @extend h1;
    }

    &.h2 {
        @extend h2;
    }

    &.h3 {
        @extend h3;
    }

    &.h4 {
        @extend h4;
    }

    &.h5 {
        @extend h5;
    }
}

.border-top {
    border-top: 2px solid $primary-2 !important;
    @extend .pt-3;
}
h1, .h1 {
    font-size: $font-size-base*2.667;
    @include media-breakpoint-down(md) {
        font-size: $font-size-base*2.135;
    }
}

h2, .h2 {
    font-size: $font-size-base*2 ;
    @include media-breakpoint-down(md) {
        font-size: $font-size-base*1.423;
    }
}

h3,
.h3 {
    font-size: $font-size-base*1.333;
    color: theme-color('primary-2');
    @include media-breakpoint-down(md) {
        font-size: $font-size-base*1.245;
    }
}

h4, .h4 {
    font-size: $font-size-base;
    text-transform: uppercase;
    font-weight: $font-weight-normal;
    letter-spacing: .2rem;
}

.lead {
    font-size: $lead-font-size;
    @include media-breakpoint-down(md) {
        font-size: $font-size-base*1.066;
    }
}