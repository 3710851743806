
.slider {
    .carousel-cell {
        background-size: cover;
    }

    .is-nav-selected {
        h3 {
            color: red;
        }
    }

    &.slider-nav {
        @extend .col-md-6;
        postition: relative;
        top: -8em;
    }
}
.flickity-prev-next-button {
    border: none;
}
