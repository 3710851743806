.process-vertical {
    .banner-strap & {
        color: $white;
    }

    font-weight: bold;

    .history_title {
        @extend .mb-0;
        @extend .text-primary;
    }

    .history_description {
        @extend .mb-0;
    }
}

