.card {
    @extend .hover-shadow-3d;
    &.card-icon {
        border-color: theme-color('primary-3');
        @extend .card-icon-3;
        @extend .justify-content-between;
        .icon-round {
            background: theme-color('primary-3');
            transform: scale(3);
            top: 2rem;
            left: 2rem;
            img {
                @extend .mt-2;
                @extend .ml-1;
                max-width: 26px;
                max-height: 26px;
            }
        }
        .card-body {
            @extend .mt-7;
        }
    }
    .linkItem {
        color: theme-color('primary');
        @extend .mb-0;
        text-decoration: underline;
    }

    transition-duration: .5s;
    &:hover {
        box-shadow: 0 20px 40px rgba($primary-3, .3);
        background-color: $white;
    }

}

// a.card {
//     .linkItem {
//         color: theme-color('primary');
//     }
// }