$dimensions: 16em;

.stoerer {
    @extend .p-5;
    
    
    margin-top: 5rem;
    padding-top: 2rem !important;
    position: absolute;
    z-index: +2;
    top: 0;
    right: 0;
    width: $dimensions;
    height: $dimensions;
    border-radius: $dimensions/2;
    // border: 2px solid;
    background: $white;
    // transform: rotate(-5deg);
    @media (min-width: 1366px) and (max-width: 1440px) {
        margin-top: 0;
    }
    @media (min-width: 960px) and (max-width: 1366px) {
        margin-top: -5rem;
    }
    @include media-breakpoint-down(md) {
        margin-top: -7.5rem;
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }

    p {
        @extend .mb-0;
    }

    a,
    p,
    .h3 {
        @extend .text-white;
    }

    img {
        width: 60px;
        height: 60px;
        @extend .mb-2;
    }

    .text-center {
        width: 100%;
    }
}
