.btn {
    @extend p;
    border-radius: 6px !important;
    font-family: $headings-font-family;
    font-weight: 600;
    letter-spacing: 0;
    font-size: $font-size-base;
    // text-transform: uppercase;

    &:hover {
        cursor: pointer;
        background: $primary !important;
        border-color: $primary !important;
        color: $white !important;
    }

    &.btn-outline-primary {
        border: $border-width solid $primary;
        font-weight: 300;
        color: $primary-2;
        .bg-primary-2 & {
            color: $white;
        }
    }

}

.navbar {
    .btn {
        @extend .btn-primary;
        border: 2px solid theme-color('primary-2');
        background: none;
        color: theme-color('primary');

    }
}