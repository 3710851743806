.utility {
    @extend .py-1;
    @extend .bg-primary;
    @extend .text-light;
    @extend .layer-2;
    border-bottom: 4px solid theme-color('primary');

    a {
        font-weight: $font-weight-bold;
    }

    font-size: 0.75em;

    .dropdown {}

    .dropdown-menu {
        @extend .p-1;
        font-size: 1em;
        border: none;
        border-radius: 0 !important;
    }

    .dropdown-item {
        @extend .p-0;
        width: auto;
    }
}